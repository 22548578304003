import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import formatPrice from '../ultils/formatPrice';

const UNIT_ARR = ['', 'nghìn', 'triệu', 'tỷ', 'nghìn tỷ', 'triệu tỷ'];

const convertToVietnameseWords = (val) => {
  try {
    if (val == null) {
      return "";
    }

    let input = -1;
    if (typeof val === 'number') {
      input = Math.round(val);
    } else {
      input = Math.round(parseFloat(val.toString()));
    }

    if (input === 0) {
      return "Không đồng chẵn.";
    }

    const cacheInput = input;

    let rsStr = "";
    let negative = false;
    if (input < 0) {
      negative = true;
      input *= -1;
    }

    if (input > 999999999999999) {
      return "Số tiền không hợp lệ.";
    }

    const group3NumberList = [];

    while (input > 0) {
      const group3Number = input % 1000;
      group3NumberList.push(group3Number);

      input = Math.floor(input / 1000);
    }

    let byPassFirstUnit = false;
    for (let i = group3NumberList.length - 1; i >= 0; i--) {
      const str = formatGroup3Number(group3NumberList[i], i === 0 && rsStr !== '');

      if (str !== '') {
        rsStr += " " + str + " " + UNIT_ARR[i];
      }

      if (byPassFirstUnit === false) {
        switch (group3NumberList.length) {
          case 5:
            if (i === 3) {
              byPassFirstUnit = true;
              if (str === '') {
                rsStr += " " + UNIT_ARR[i];
              }
            }
            break;
          case 6:
            if (i === 4) {
              if (str === '') {
                rsStr += " " + UNIT_ARR[i];
              }
            } else if (i === 3) {
              byPassFirstUnit = true;
              if (str === '') {
                rsStr += " " + UNIT_ARR[i];
              }
            }
            break;
          default:
            byPassFirstUnit = true;
            break;
        }
      }
    }

    rsStr = rsStr.trim();

    rsStr += " đồng";
    if (cacheInput % 2 === 0) {
      rsStr += " chẵn";
    }

    if (negative) {
      rsStr = "Âm " + rsStr;
    } else {
      rsStr = rsStr.charAt(0).toUpperCase() + rsStr.slice(1);
    }

    return rsStr + ".";
  } catch (e) {
    console.error(e);
    return "Số tiền không hợp lệ";
  }
};

const formatGroup3Number = (i, fullFill) => {
  if (i === 0) {
    return "";
  }

  if (i < 10) {
    let rs = number2Vn(i, 0);
    if (fullFill) {
      rs = "không trăm lẻ " + rs;
    }
    return rs;
  }

  if (i < 100) {
    const chuc = Math.floor(i / 10);
    const dv = i % 10;
    let rs = "";

    if (chuc === 1) {
      rs = "mười";
    } else {
      rs = number2Vn(chuc, 0) + " mươi";
    }

    const dvStr = number2Vn(dv, chuc === 1 && dv === 1 ? 0 : 1);

    if (dvStr !== '') {
      rs += " " + dvStr;
    }

    if (fullFill) {
      rs = "không trăm " + rs;
    }

    return rs;
  }

  let rs = number2Vn(Math.floor(i / 100), 0) + " trăm";

  const chucDv = i % 100;
  const chuc = Math.floor(chucDv / 10);
  const dv = chucDv % 10;

  if (chuc !== 0 || dv !== 0) {
    switch (chuc) {
      case 0:
        rs += " lẻ";
        break;
      case 1:
        rs += " mười";
        break;
      default:
        rs += " " + number2Vn(chuc, 0) + " mươi";
        break;
    }

    if (dv > 0) {
      rs += " " + number2Vn(dv, chuc <= 1 ? 0 : 1);
    }
  }

  return rs;
}

const number2Vn = (i, mCase) => {
  switch (i) {
    case 0:
      return "";
    case 1:
      return mCase === 0 ? "một" : "mốt";
    case 2:
      return "hai";
    case 3:
      return "ba";
    case 4:
      return "bốn";
    case 5:
      return mCase === 0 ? "năm" : "lăm";
    case 6:
      return "sáu";
    case 7:
      return "bảy";
    case 8:
      return "tám";
    case 9:
      return "chín";
    default:
      return "ERROR";
  }
}

const PrintForm = ({ visible, onHide, formData }) => {
  const handlePrint = () => {
    const printWindow = window.open('_parent', '', 'fullscreen=yes');
    printWindow.document.write('<html><body >');
    printWindow.document.write(document.getElementById('printable-content').innerHTML);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
  };

  const totalPriceInWords = convertToVietnameseWords(parseInt(formData.totalPrice) || 0);
  const renderFooter = () => {
    return (
      <div>
        <Button label="Print" icon="pi pi-print" onClick={handlePrint} />
      </div>
    );
  };

  return (
    <Dialog
      visible={visible}
      maximizable={true}
      onHide={onHide} 
      style={{ width: '80vw' }} 
      header="Print Preview" 
      footer={renderFooter()}
      breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
      <div id="printable-content" className="print-form">
        <div style={{ textAlign: 'center', fontSize: '18px', fontWeight: '700' }}>CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM</div>
        <div style={{ textAlign: 'center', fontSize: '18px', fontWeight: '700', fontStyle: 'italic', marginBlock: '4px' }}>Độc lập - Tự do - Hạnh phúc</div>
        <div style={{ textAlign: 'center', fontSize: '18px', fontWeight: '700', fontStyle: 'italic' }}> ---------- o0o -----------</div>
        <br />
        <h1 style={{ textAlign: 'center' }}>HỢP ĐỒNG MUA BÁN HÀNG HOÁ</h1>
        <p style={{ textAlign: 'center', fontStyle: 'italic' }}>Số: 24.08-05/THĐ/HĐMBTM</p>
        <i>Căn cứ:</i>
        <p style={{ marginLeft: '30px', fontStyle: 'italic' }}>-	Căn cứ vào Bộ luật Dân sự số 91/2015/QH13 được Quốc hội nước CHXHCN Việt Nam ban hành ngày 24 tháng 11 năm 2015, có hiệu lực từ ngày 01 tháng 01 năm 2017 và các văn bản hướng dẫn thi hành; <br />
          -	Căn cứ Luật Thương mại số 36/2005/QH11 được Quốc hội nước CHXHCN Việt Nam ban hành ngày 14 tháng 06 năm 2005, có hiệu lực từ ngày 01 tháng 01 năm 2006  và văn bản hướng dẫn thi hành; <br />
          -	Căn cứ nhu cầu và khả năng của Hai Bên. <br />
        </p>
        <i> Hôm nay, ngày 09 tháng 08 năm 2024, Chúng tôi gồm có:</i>
        <h2>BÊN BÁN: {formData.benBan || '_______________'}</h2>
        <p>Số CCCD: {formData.sellerIdNumber || '_______________'}</p>
        <p>Ngày cấp: {formData.sellerIdDate || '_______________'}</p>
        <p>Nơi cấp: {formData.sellerIdPlace || '_______________'}</p>
        <p>Địa chỉ: {formData.diaChi || '_______________'}</p>
        <p>Số điện thoại: {formData.soDienThoai || '_______________'}</p>
        Sau đây gọi tắt là Bên A
        <br /><br />
        <h2>BÊN MUA: CỬA HÀNG CÔNG TY TNHH THƯƠNG MẠI GEARVN</h2>
        <p>Địa chỉ: 82 Hoàng Hoa Thám, Phường 12, Quận Tân Bình, TP. Hồ Chí Minh</p>
        <p>Điện thoại: (028) 7778 7999</p>
        <p>Mã số thuế: 0316517394</p>
        <p>Nhân viên kĩ thuật: {formData.technician || '_______________'}</p>
        <p>Quản lý cửa hàng: {formData.manager || '_______________'}</p>
        Sau đây gọi tắt là Bên B
        <br /><br />
        Trên cơ sở thỏa thuận, hai bên thống nhất ký kết hợp đồng mua bán hàng hóa với các điều khoản như sau:<br /><br />
        <h2>ĐIỀU 1: TÊN HÀNG - SỐ LƯỢNG - GIÁ TRỊ HỢP ĐỒNG</h2>
        <p style={{ textAlign: 'right', fontStyle: 'italic' }}>Đơn vị tính: đồng</p>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th style={{ border: '1px solid black', padding: '5px' }}>STT</th>
              <th style={{ border: '1px solid black', padding: '5px' }}>Tên hàng hoá</th>
              <th style={{ border: '1px solid black', padding: '5px' }}>Đơn vị</th>
              <th style={{ border: '1px solid black', padding: '5px' }}>Số lượng</th>
              <th style={{ border: '1px solid black', padding: '5px' }}>Đơn giá</th>
              <th style={{ border: '1px solid black', padding: '5px' }}>Thành tiền</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ border: '1px solid black', padding: '5px' }}>1</td>
              <td style={{ border: '1px solid black', padding: '5px' }}>VGA {formData.productName || '_______________'}</td>
              <td style={{ border: '1px solid black', padding: '5px' }}>{formData.unit || '_______________'}</td>
              <td style={{ border: '1px solid black', padding: '5px' }}>{formData.quantity || '_______________'}</td>
              <td style={{ border: '1px solid black', padding: '5px' }}>{formatPrice(formData.unitPrice) || '_______________'}</td>
              <td style={{ border: '1px solid black', padding: '5px' }}>{formatPrice(formData.totalPrice) || '_______________'}</td>
            </tr>
            <tr>
              <td colSpan="5" style={{ border: '1px solid black', padding: '5px', textAlign: 'right' }}><strong>Tổng cộng:</strong></td>
              <td style={{ border: '1px solid black', padding: '5px' }}>{formatPrice(formData.totalPrice) || '_______________'}</td>
            </tr>
            <tr>
              <td colSpan="6" style={{ border: '1px solid black', padding: '5px', textAlign: 'right', fontStyle: 'italic' }}>
                <strong>Số tiền bằng chữ:</strong> {totalPriceInWords}
              </td>
            </tr>
          </tbody>
        </table>

        <br />
        <h2>ĐIỀU 2: THÔNG SỐ KĨ THUẬT CỦA HÀNG HÓA</h2>
        <p style={{ textAlign: 'right', fontStyle: 'italic' }}>Đơn vị tính: đồng</p>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th style={{ border: '1px solid black', padding: '5px' }}>STT</th>
              <th style={{ border: '1px solid black', padding: '5px' }}>Tên hàng hoá</th>
              <th style={{ border: '1px solid black', padding: '5px' }}>Imei</th>
              <th style={{ border: '1px solid black', padding: '5px' }}>Màu sắc</th>
              <th style={{ border: '1px solid black', padding: '5px' }}>Thông số kĩ thuật</th>
              <th style={{ border: '1px solid black', padding: '5px' }}>Tình trạng máy</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ border: '1px solid black', padding: '5px' }}>1</td>
              <td style={{ border: '1px solid black', padding: '5px' }}>VGA {formData.productName || '_______________'}</td>
              <td style={{ border: '1px solid black', padding: '5px' }}>{formData.imei || '_______________'}</td>
              <td style={{ border: '1px solid black', padding: '5px' }}>{formData.color || '_______________'}</td>
              <td style={{ border: '1px solid black', padding: '5px' }}>{formData.specifications || '_______________'}</td>
              <td style={{ border: '1px solid black', padding: '5px' }}>{formData.condition || '_______________'}</td>
            </tr>
            <tr>
              <td colSpan="5" style={{ border: '1px solid black', padding: '5px', textAlign: 'right' }}><strong>Tổng cộng:</strong></td>
              <td style={{ border: '1px solid black', padding: '5px' }}>{formatPrice(formData.totalPrice) || '_______________'}</td>
            </tr>
            <tr>
              <td colSpan="6" style={{ border: '1px solid black', padding: '5px', textAlign: 'right', fontStyle: 'italic' }}>
                <strong>Số tiền bằng chữ:</strong> {totalPriceInWords}
              </td>
            </tr>
          </tbody>
        </table>

        <h2>ĐIỀU 3: BÀN GIAO HÀNG HÓA</h2>
        <p>3.1 Bên mua và bên bán thống nhất các thông số kĩ thuật tại thời điểm bàn giao tại điều 2 và được thực hiện nghiêm ngặt đầy đủ dưới sự giám sát của quản lý cửa hàng</p>
        <p>3.2 Sau khi Bên Bán thực hiện ký hợp đồng và bàn giao hàng hóa, hàng hóa được chuyển giao sở hữu cho bên mua.</p>

        <h2>ĐIỀU 4: THỜI HẠN VÀ PHƯƠNG THỨC THANH TOÁN</h2>
        <p>4.1 Bên B phải thanh toán cho Bên A tổng số tiền ghi tại Điều 1 của Hợp đồng này là: {formatPrice(formData.totalPrice) || '_______________'} VNĐ (Bằng chữ: <i>{totalPriceInWords}</i>).
          Giá trên đã bao gồm thuế giá trị gia tăng (nếu có)</p>
        <p>4.2 Thời hạn thanh toán: Bên B sẽ thanh toán cho bên A sau khi bên B nhận được hàng.</p>
        <p>4.3 Phương thức thanh toán: Thanh toán bằng tiền mặt hoặc chuyển khoản. <br />
          Khi Bên B thanh toán tiền hàng, Bên B có nghĩa vụ ghi nhận chứng từ việc đã thanh toán cho Bên A theo qui định của pháp luật cũng như hai bên ký biên bản giao nhận hàng hoá
        </p>

        <h2>ĐIỀU 5: NGHĨA VỤ CỦA BÊN BÁN</h2>
        <p>5.1 Bên Bán chịu trách nhiệm về số lượng, chất lượng và nguồn gốc hàng hóa đối với toàn bộ các sản phẩm do Bên Bán cung cấp.</p>
        <p>5.2 Trong thời gian bảo hành của sản phẩm, bên Bán uỷ quyền cho bên Mua làm việc trực tiếp với nhà phân phối gốc hoặc hãng sản xuất để thực hiện nghĩa vụ bảo hành.</p>
        <p>5.3 Bên Bán có nghĩa vụ giao hàng tại các cửa hàng thuộc hệ thống của Bên mua.</p>
        <p>5.4 Bên Bán có nghĩa vụ cung cấp mọi chỉ dẫn cần thiết đối với việc bảo quản, sử dụng hàng hoá theo quy định của Hợp đồng này cho Bên mua.</p>

        <h2>ĐIỀU 6: NGHĨA VỤ CỦA BÊN MUA</h2>
        <p>6.1 Tổ chức tiếp nhận hàng nhanh, an toàn, dứt điểm cho từng lần nhận hàng từ bên Bán.</p>
        <p>6.2 Thanh toán theo quy định tại Điều 4 Hợp đồng này.</p>

        <h2>ĐIỀU 7: THANH LÝ HỢP ĐỒNG</h2>
        <p>Khi 2 Bên đã thực hiện đầy đủ và nghiêm chỉnh các điều khoản trong Hợp đồng này, mà không có vướng mắc gì thì hợp đồng coi như đã được thanh lý.</p>

        <h2>ĐIỀU 8: GIẢI QUYẾT TRANH CHẤP</h2>
        <p>Trong quá trình thực hiện Hợp đồng này nếu xảy ra bất kỳ sự bất đồng nào, Bên nảy sinh bất đồng sẽ thông báo cho bên kia bằng văn bản. Hai bên sẽ thương lượng để giải quyết các bất đồng đó. Trường hợp các bên không tự thương lượng được thì sự việc sẽ được đưa ra giải quyết theo qui định của pháp luật.</p>

        <h2>ĐIỀU 9: CÁC TRƯỜNG HỢP CHẤM DỨT HỢP ĐỒNG</h2>
        <p>Hợp đồng này sẽ được chấm dứt trong các trường hợp sau:</p>
        <p>- Khi các bên thực hiện xong các quyền và nghĩa vụ quy định trong hợp đồng này.</p>
        <p>- Khi một bên vi phạm hợp đồng dẫn đến hợp đồng không thực hiện được thì bên còn lại có quyền đơn phương chấm dứt hợp đồng.</p>
        <p>- Hợp đồng có thể được chấm dứt do sự thỏa thuận của các Bên.</p>

        <h2>ĐIỀU 10: HIỆU LỰC THI HÀNH</h2>
        <p>Hợp đồng này có hiệu lực kể từ ngày ký và chỉ kết thúc khi các Bên đã hoàn thành các nghĩa vụ của mình trong hợp đồng.</p>
        <p>Hợp đồng này được lập thành 02 bản, mỗi Bên giữ 01 bản, các bản có giá trị pháp lý như nhau.</p>

        <div className="signatures" style={{ display: 'flex', justifyContent: 'center', gap: '50%', marginTop: '30px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h3><strong>ĐẠI DIỆN BÊN BÁN</strong></h3>
            <br />
            <p>_______________</p>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h3><strong>ĐẠI DIỆN BÊN MUA</strong></h3>
            <br />
            <p>_______________</p>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default PrintForm;