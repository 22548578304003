import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import PrintForm from './PrintForm';
import "./ConfirmPurchaseDialog.css";

const initialFormState = {
  benBan: '',
  soCCCD: '',
  noiCap: 'Cục Cảnh sát quản lý hành chính về trật tự xã hội, Bộ Công an',
  ngayCap: null,
  diaChi: '',
  soDienThoai: '',
  imei: ''
};

const ConfirmPurchaseDialog = ({ visible, onHide, productName, condition, totalPrice }) => {
  const [formData, setFormData] = useState(initialFormState);
  const [errors, setErrors] = useState({});
  const [showPrintForm, setShowPrintForm] = useState(false);

  const resetForm = () => {
    setFormData(initialFormState);
    setErrors({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
    // Clear the error for this field
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: undefined
    }));
  };

  const handleDateChange = (e) => {
    setFormData(prevState => ({
      ...prevState,
      ngayCap: e.value
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.benBan.trim()) newErrors.benBan = 'Bên bán là bắt buộc !';
    if (!formData.soCCCD.trim()) {
      newErrors.soCCCD = 'Số CCCD là bắt buộc !';
    }
    else {
      // Kiem tra CCCD co dung 12 chu so
      if (!(/^\d{12}$/.test(formData.soCCCD.trim().replace(/\s+/g, '')))) newErrors.soCCCD = 'CCCD không hợp lệ !';
    }
    if (!formData.diaChi.trim()) newErrors.diaChi = 'Địa chỉ là bắt buộc !';
    if (!formData.soDienThoai.trim()) {
      newErrors.soDienThoai = 'SĐT là bắt buộc !';
    }
    else {
      // Kiem tra SĐT co >= 8 chu so
      if (!(/^\d{8,}$/.test(formData.soDienThoai.trim().replace(/\s+/g, '')))) newErrors.soDienThoai = 'SĐT không hợp lệ !';
    }
    if (!formData.imei.trim()) {
      newErrors.imei = 'IMEI là bắt buộc !';
    }
    else {
      // Kiem tra IMEI chi chua number
      if (!(/^\d+$/.test(formData.imei))) newErrors.imei = 'IMEI không hợp lệ !';
    }


    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  const handlePrint = () => {
    if (validateForm()) {
      setShowPrintForm(true);
    } else {
      console.log('Form is invalid');
    }
  };

  const handleDialogHide = () => {
    resetForm();
    onHide();
  };

  const renderFooter = () => {
    return (
      <div>
        <Button label="In" icon="pi pi-print" onClick={handlePrint} autoFocus />
        <Button label="Hủy" onClick={onHide} className="p-button-secondary"/>
      </div>
    );
  };

  useEffect(() => {
    if (!visible) {
      resetForm();
    }
  }, [visible]);

  return (
    <>
      <Dialog
        header="Xác nhận mua"
        visible={visible}
        style={{ width: '50vw' }}
        footer={renderFooter()}
        onHide={handleDialogHide}
      >
        <div className="p-fluid" style={{paddingBlock: '0px'}}>
          {/* <div style={{display: 'flex', justifyContent: 'space-between', gap: '30px'}}> */}
              <div className="p-field">
                <label htmlFor="benBan">Bên bán</label>
                <InputText
                  id="benBan"
                  name="benBan"
                  placeholder='Nhập tên Bên Bán'
                  value={formData.benBan}
                  onChange={handleInputChange}
                  className={classNames({ 'p-invalid': errors.benBan })}
                />
                {errors.benBan && <small className="p-error">{errors.benBan}</small>}
              </div>
          {/* </div> */}
          <div className="p-field" style={{width: '150px'}}>
            <label htmlFor="soDienThoai">Số điện thoại</label>
            <InputText
              id="soDienThoai"
              name="soDienThoai"
              placeholder='Nhập SĐT'
              value={formData.soDienThoai}
              onChange={handleInputChange}
              className={classNames({ 'p-invalid': errors.soDienThoai })}
            />
            {errors.soDienThoai && <small className="p-error">{errors.soDienThoai}</small>}
          </div>
          <div className="p-field" style={{flexGrow: '2'}}>
            <label htmlFor="diaChi">Địa chỉ</label>
            <InputTextarea
              id="diaChi"
              name="diaChi"
              rows={3}
              placeholder='Nhập địa chỉ'
              value={formData.diaChi}
              onChange={handleInputChange}
              className={classNames({ 'p-invalid': errors.diaChi })}
            />
            {errors.diaChi && <small className="p-error">{errors.diaChi}</small>}
          </div>
          <div style={{display: 'flex', gap: '30px'}}>
            <div className="p-field" style={{width: '150px'}}>
                <label htmlFor="soCCCD">Số CCCD</label>
                <InputText
                  id="soCCCD"
                  name="soCCCD"
                  placeholder='Nhập số CCCD'
                  value={formData.soCCCD}
                  onChange={handleInputChange}
                  className={classNames({ 'p-invalid': errors.soCCCD })}
                />
                {errors.soCCCD && <small className="p-error">{errors.soCCCD}</small>}
            </div>
            <div className="p-field" style={{width: '200px'}}>
              <label htmlFor="ngayCap">Ngày cấp</label>
              <Calendar
                id="ngayCap"
                placeholder='Nhập ngày cấp'
                value={formData.ngayCap}
                onChange={handleDateChange}
                dateFormat="dd/mm/yy"
                showIcon
              />
            </div>
            <div className="p-field" style={{flexGrow: '2'}}>
              <label htmlFor="noiCap">Nơi cấp</label>
              <InputText
                id="noiCap"
                name="noiCap"
                placeholder='Nhập nơi cấp'
                value={formData.noiCap}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="p-field">
            <label htmlFor="imei">IMEI</label>
            <InputText
              id="imei"
              name="imei"
              placeholder='Nhập IMEI sản phẩm'
              value={formData.imei}
              onChange={handleInputChange}
              className={classNames({ 'p-invalid': errors.imei })}
            />
            {errors.imei && <small className="p-error">{errors.imei}</small>}
          </div>
        </div>
      </Dialog>
      <PrintForm
        visible={showPrintForm}
        onHide={() => setShowPrintForm(false)}
        formData={{
          ...formData,
          benBan: formData.benBan,
          soDienThoai: formData.soDienThoai,
          diaChi: formData.diaChi,
          sellerIdNumber: formData.soCCCD,
          sellerIdDate: formData.ngayCap ? formData.ngayCap.toLocaleDateString('vi-VN') : '',
          sellerIdPlace: formData.noiCap,
          imei: formData.imei,
          productName: productName, // Use the passed productName
          unit: 'Cái',
          quantity: '1',
          unitPrice: totalPrice.toString(), // Use the passed totalPrice as unitPrice
          totalPrice: totalPrice.toString(), // Use the passed totalPrice
          totalPriceInWords: '', // You may want to implement a function to convert number to words
          color: '', // You may want to add this to the form
          specifications: '', // You may want to add this to the form
          condition: condition, // You may want to make this dynamic
          sellerSignDate: new Date().toLocaleDateString('vi-VN'),
          buyerSignDate: new Date().toLocaleDateString('vi-VN'),
        }}
      />
    </>
  );
};

export default ConfirmPurchaseDialog;