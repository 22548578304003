import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import SearchBar from './SearchBar';

const ProductListDialog = ({ visible, onHide, products, filteredProducts, selectedProduct, onSearch, onRowClick, showPrice, condition, onConfirm, onCancel }) => {
  return (
    <Dialog
      header="Chọn sản phẩm"
      visible={visible}
      style={{ width: '60vw', height: 'fit-content' }}
      onHide={onCancel}
    >
      <SearchBar data={products} onSearch={onSearch} />
      <div className="table-container" style={{maxHeight: '400px'}}>
        <table>
          <thead>
            <tr>
              <th>Dòng</th>
              <th>Giá Bình thường</th>
              <th>Giá Hư, còn xuất hình</th>
              <th>Giá Không hoạt động</th>
            </tr>
          </thead>
          <tbody>
            {filteredProducts.map((product, index) => (
              <tr
                key={index}
                onClick={() => onRowClick(product)}
                style={{ cursor: 'pointer' }}
                className={
                  selectedProduct && selectedProduct.Dòng === product.Dòng
                    ? "selected-row"
                    : ""
                }
              >
                <td>{product.Dòng}</td>
                <td>{showPrice(product, 'Bình thường')}</td>
                <td>{showPrice(product, 'Hư, còn xuất hình')}</td>
                <td>{showPrice(product, 'Không hoạt động')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
        <Button label="Chọn" onClick={onConfirm} className="p-button-primary" style={{ marginRight: '10px' }}/>
        <Button label="Hủy" onClick={onCancel} className="p-button-secondary"/>
      </div>
    </Dialog>
  );
};

export default ProductListDialog;