import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import ProductPricing from './pages/ProductPricing';
import PriceInsert from './pages/PriceInsert';
import Sidebar from './components/Sidebar';
import fetchProductData from './ultils/fetchProductData';

// PrimeReact imports
import "primereact/resources/primereact.min.css";                  // core css
import "primeicons/primeicons.css";                                // icons
import "primereact/resources/themes/lara-light-indigo/theme.css";  // theme

import './App.css';
import './themes/gearvn.css';

import {REACT_APP_BACKEND_URL, PUBLIC_URL} from './config';

function App() {
  const [productData, setProductData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadProductData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const data = await fetchProductData();
        setProductData(data);
        // console.log(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    loadProductData();
  }, []);

  const updateProductData = (newData) => {
    setProductData(newData);
  };

  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return (
      <div className="error">
        <h2>Error loading product data</h2>
        <p>{error}</p>
        <p>Please check the following:</p>
        <ul>
          <li>Ensure the server is running on {REACT_APP_BACKEND_URL}</li>
          <li>Verify that the productData.json file exists in the server's data folder</li>
          <li>Check the server console for any error messages</li>
        </ul>
        <button className='mybutton' onClick={() => window.location.reload()}>Retry</button>
      </div>
    );
  }

  if (!productData) {
    return <div className="error">No product data available. Please check the server and try again.</div>;
  }

  return (
    <Router>
      <div className="App">
        <Sidebar />
        <div className="main-content">
          <Routes>
            <Route path={`${PUBLIC_URL}/`} element={<Navigate to={`${PUBLIC_URL}/product-pricing`} replace />} />
            <Route 
              path={`${PUBLIC_URL}/product-pricing`} 
              element={<ProductPricing productData={productData} />} 
            />
            <Route 
              path={`${PUBLIC_URL}/price-insert`} 
              element={<PriceInsert productData={productData} updateProductData={updateProductData} />} 
            />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
