import { DEV_TOKEN } from "../config";

class LocalStorageServices {
  get(key) {
    try {
      let data = localStorage.getItem(key) || "";

      if (!key) {
        return null;
      }

      if (data) {
        return JSON.parse(data);
      }

      return null;
    } catch (e) {
      console.error("Error get from localStorage", e);
      return null;
    }
  }

  getCurrenUser() {
    let currentUserLocal = this.get("user");
    return {
      id: currentUserLocal?.profile?.email || "N/A",
      email: currentUserLocal?.profile?.email || "N/A",
      username: currentUserLocal?.profile?.username || "N/A",
      name: currentUserLocal?.profile?.name || "N/A",
    };
  }

  getToken() {
    let tokenInfo = this.get("token") || {};
    return tokenInfo.token || DEV_TOKEN;
  }
}

const MyServices = new LocalStorageServices();
export default MyServices;
