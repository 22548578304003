import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Sidebar.css';

import {PUBLIC_URL} from '../config'

const Sidebar = () => {
  const location = useLocation();

  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <h2>Định giá hàng cũ</h2>
      </div>
      <nav className="sidebar-nav">
        <ul>
          <li>
            <Link 
              to={`${PUBLIC_URL}/product-pricing`}
              className={location.pathname === `${PUBLIC_URL}/product-pricing` ? 'active' : ''}
            >
              Định giá sản phẩm
            </Link>
          </li>
          <li>
            <Link 
              to={`${PUBLIC_URL}/price-insert`}
              className={location.pathname === `${PUBLIC_URL}/price-insert` ? 'active' : ''}
            >
              Nhập giá sản phẩm
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;