import BaseServices from "./base.services";

class ProductServices extends BaseServices {
  constructor() {
    super(`${process.env.REACT_APP_BACKEND_URL}/api/proxy-pim/`);
  }

  async getProductList(paras) {
    paras = {
      ...{
        page_size: 200,
        page_index: 1,
        noUrl: true,
        parent: 99, // sản phẩm mới lẫn cũ
      },
      ...paras,
    };

    return await this.get(
      "product/list/v2",
      "getListProductV2",
      "product",
      paras
    );
  }

  async insertProduct(data) {
    return await this.post("product/insert", "insert", "product", data);
  }

  async getBrandList() {
    return await this.get("product-brand", "product-brand", "product", {
      page_size: 1000,
      page_index: 1,
    });
  }

  async getCategoryList() {
    return await this.get("category", "category", "product", {
      page_size: 1000,
      page_index: 1,
    });
  }
}

const MyServices = new ProductServices();
export default MyServices;
