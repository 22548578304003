import React, { useState, useEffect, useRef } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import brandTierData from "../data/brand-tier.json";
import { InputText } from "primereact/inputtext";
import SelectInput from "../components/SelectInput";
import RadioButton from "../components/RadioButton";
import ConfirmPurchaseDialog from '../components/ConfirmPurchaseDialog';
import ProductListDialog from '../components/ProductListDialog';
import { Loader } from "../components";

import ProductPimSelect from "./ProductPimSelect";

import "./ProductPricing.css";

// TODO gọi api 
const brandOptions = [
  { value: 'Asus', label: 'Asus' },
  { value: 'Gigabyte', label: 'Gigabyte' },
  { value: 'MSI', label: 'MSI' },
  { value: 'Colourful', label: 'Colourful' },
  { value: 'Asrock', label: 'Asrock' },
  { value: 'Inno3D', label: 'Inno3D' },
  { value: 'PNY', label: 'PNY' },
  { value: 'Zotac', label: 'Zotac' },
  { value: 'Galax', label: 'Galax' },
  { value: 'Gainward', label: 'Gainward' },
  { value: 'Manli', label: 'Manli' },
  { value: 'Palit', label: 'Palit' },
  { value: 'ASL', label: 'ASL' }
];

const ProductPricing = ({ productData }) => {
  const [state, setState] = useState({
    loading: false,
    showProductPimSelector: false,
  });

  const [condition, setCondition] = useState("Bình thường");
  const [brand, setBrand] = useState(brandOptions[0].value);
  const [appearance, setAppearance] = useState("Mới, đẹp");
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [tempSelectedProduct, setTempSelectedProduct] = useState(null);
  const [selectedPimProduct, setSelectedPimProduct] = useState(null);
  const [bill, setBill] = useState([]);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [productListDialogVisible, setProductListDialogVisible] = useState(true);
  const [warrantyDate, setWarrantyDate] = useState(null);
  const [hasBox, setHasBox] = useState(false);
  const toast = useRef(null);

  useEffect(() => {
    if (productData && productData["Sản phẩm"]) {
      setProducts(productData["Sản phẩm"]);
      setFilteredProducts(productData["Sản phẩm"]);
      setSelectedProduct(productData["Sản phẩm"][0]);
      setSelectedPimProduct(null)
    }
  }, [productData]);

  const handleSearch = (term) => {
    const filtered = products.filter(product =>
      product.Dòng.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredProducts(filtered);
  };

  useEffect(() => {
    if (filteredProducts.length > 0) {
      setTempSelectedProduct(filteredProducts[0]);
    }
  }, [filteredProducts]);

  const handleRowClick = (product) => {
    setTempSelectedProduct(product);
  };

  useEffect(() => {
    if (selectedProduct) {
      handleDinhGia();
    }
  }, [selectedProduct, condition, brand, appearance, warrantyDate, hasBox]);

  const formatPrice = (price) => {
    if (price === '') return 'N/A';
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const showPrice = (product, condition) => {
    switch (condition) {
      case 'Bình thường':
        return formatPrice(product['Giá Base']);
      case 'Hư, còn xuất hình':
        let price = product['Giá hàng hư hỏng'];
        let p = price ? (parseInt(price.replace(/[\s,]/g, ''), 10) + 100).toString() : '';
        return formatPrice(p);
      case 'Không hoạt động':
        return formatPrice(product['Giá hàng hư hỏng']);
      default:
        return '';
    }
  }

  const getBrandTier = (brand) => {
    if (!brand) return null;
    for (const [tier, brands] of Object.entries(brandTierData)) {
      if (brands.includes(brand)) {
        return tier;
      }
    }
    return null;
  }

  const isRTX3060OrHigher = (dong) => {
    const rtxRegex = /RTX\s*(\d{4})/i;
    const match = dong.match(rtxRegex);
    if (match) {
      const modelNumber = parseInt(match[1]);
      return modelNumber >= 3060;
    }
    return false;
  }

  const calculateTotal = (billItems) => {
    let total = 0;
    billItems.forEach((item) => {
      const priceString = item.gia.replace(/^[+\s]+/, '').replace(/,/g, '');
      if (priceString !== 'N/A') {
        const price = parseInt(priceString, 10);
        if (!isNaN(price)) {
          total += price;
        }
      }
    });
    return Math.round(total);
  };

  const getWarrantyStatus = () => {
    if (!warrantyDate) return "Hết";
    
    const expirationDate = new Date(warrantyDate);
    const currentDate = new Date();
    const monthsDifference = (expirationDate.getFullYear() - currentDate.getFullYear()) * 12 +
      (expirationDate.getMonth() - currentDate.getMonth());

    if (monthsDifference >= 12) {
      return ">= 12 tháng";
    } else if (monthsDifference > 0) {
      return "< 12 tháng";
    } else {
      return "Hết";
    }
  };

  const calculateBill = (product) => {
    if (product && condition) {
      const basePrice = showPrice(product, condition);
      const billItems = [
        {
          muc: `Giá ${condition}`,
          gia: `${basePrice === '' || basePrice === 'N/A' ? 'N/A' : `${basePrice},000`}`
        }
      ];

      if (condition === 'Bình thường') {
        const brandTier = getBrandTier(brand);
        if (!brandTier) {
          toast.current.show({
            severity: 'error',
            summary: 'Invalid Brand',
            detail: 'Vui lòng chọn 1 thương hiệu hợp lệ !',
            life: 5000
          });
          setBill([]);
          return;
        }

        const basePriceValue = parseInt(basePrice.replace(/,/g, ''), 10) * 1000;
        const brandTierValue = productData.Brand[`Brand ${brandTier.toLowerCase()}`];
        const warrantyStatus = getWarrantyStatus();
        const warrantyValue = productData["Bảo hành"][warrantyStatus];
        const appearanceValue = productData["Ngoại hình"][appearance];
        const boxValue = productData["Có box RTX 3060 trở lên"];

        const brandIncrease = Math.round(basePriceValue * (brandTierValue - 1));
        const warrantyIncrease = Math.round(basePriceValue * (warrantyValue - 1));
        const appearanceIncrease = Math.round(basePriceValue * (appearanceValue - 1));

        billItems.push(
          {
            muc: `Thương hiệu ${brandTier.toLowerCase()}`,
            gia: '+ ' + formatPrice(brandIncrease)
          },
          {
            muc: `Bảo hành ${warrantyStatus.toLowerCase()}`,
            gia: '+ ' + formatPrice(warrantyIncrease)
          },
          {
            muc: `Ngoại hình ${appearance.toLowerCase()}`,
            gia: '+ ' + formatPrice(appearanceIncrease)
          }
        );

        if (isRTX3060OrHigher(product['Dòng']) && hasBox) {
          billItems.push({
            muc: `Có box ${product['Dòng']}`,
            gia: `+ ${boxValue},000`
          });
        }
      }
      setBill(billItems);
    }
  };

  const handleDinhGia = () => {
    const missingFields = [];

    if (!condition) missingFields.push("Tình trạng");
    if (!selectedProduct) missingFields.push("Sản phẩm");

    if (condition === 'Bình thường') {
      if (!brand || brand === '__') missingFields.push("Thương hiệu");
      if (!appearance) missingFields.push("Ngoại hình");
    }

    if (missingFields.length > 0) {
      const missingFieldsString = missingFields.join(", ");
      toast.current.show({
        severity: 'error',
        summary: 'Missing Information',
        detail: `Bạn chưa chọn thông tin: ${missingFieldsString}`,
        life: 5000,
      });
      setBill([]);
      return;
    }

    calculateBill(selectedProduct);
  };

  const onShowProductPimSelector = () => {
    setState({
      ...state,
      showProductPimSelector: true,
      productPriceInfo: {
        selectedPimProduct : selectedPimProduct,
        selectedProduct: selectedProduct,
        condition: condition,
        warranty: getWarrantyStatus(),
        appearance: appearance,
        brand: brand,
        price: calculateTotal(bill),
      },
    });
  };

  const handleConfirmProductSelection = () => {
    setSelectedProduct(tempSelectedProduct);
    setHasBox(false)
    setProductListDialogVisible(false);
    setSelectedPimProduct(null)
  };

  const handleCancelProductSelection = () => {
    setTempSelectedProduct(selectedProduct);
    setProductListDialogVisible(false);
  };

  const onCloseProductPimSelector = () => {
    setState({ ...state, showProductPimSelector: false });
  };

  const onConfirmProductPimSelector = (value) => {
    setState({ ...state, showProductPimSelector: false });
    setSelectedPimProduct(value);
  };

  if (!productData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="product-pricing">
      {state.loading && <Loader />}
      <Toast ref={toast} className="custom-toast" />

      <div className="product-pricing-header">
        <h2>Định giá sản phẩm</h2>
        <div className="product-data-info">
          <p>Version: {productData.version}</p>
          <p>
            Last Updated: {new Date(productData.timestamp).toLocaleString()}
          </p>
        </div>
      </div>
      <div className="product-pricing-content">
        <Card title="Mô tả sản phẩm" style={{ borderRadius: "20px", flex: "0 0 55%" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <InputText
              id="selected-dong"
              value={selectedProduct ? selectedProduct.Dòng : ""}
              readOnly
              disabled
              placeholder="Chọn sản phẩm"
              style={{ width: "230px" }}
            />
            <Button
              label="Chọn"
              onClick={() => setProductListDialogVisible(true)}
            />
          </div>
          <div className="form-group">
            <h3>Tình trạng</h3>
            <div className="radio-group">
              <RadioButton
                label="Bình thường"
                value="Bình thường"
                checked={condition === "Bình thường"}
                onChange={(e) => setCondition(e.value)}
              />
              <RadioButton
                label="Hư, còn xuất hình"
                value="Hư, còn xuất hình"
                checked={condition === "Hư, còn xuất hình"}
                onChange={(e) => setCondition(e.value)}
              />
              <RadioButton
                label="Không hoạt động"
                value="Không hoạt động"
                checked={condition === "Không hoạt động"}
                onChange={(e) => setCondition(e.value)}
              />
            </div>
          </div>
          <div
            style={{
              visibility: condition !== "Bình thường" ? "hidden" : "visible",
            }}
          >
            <div className="form-group">
              <h3>Bảo hành</h3>
              <Calendar
                value={warrantyDate}
                dateFormat="dd/mm/yy"
                onChange={(e) => setWarrantyDate(e.value)}
                showIcon
                placeholder="Chọn ngày hết hạn bảo hành"
                style={{ width: "300px" }}
              />
            </div>
            <div className="form-group">
              <h3>Ngoại hình</h3>
              <div className="radio-group">
                <RadioButton
                  label="Mới, đẹp"
                  value="Mới, đẹp"
                  checked={appearance === "Mới, đẹp"}
                  onChange={(e) => setAppearance(e.value)}
                />
                <RadioButton
                  label="Trung bình"
                  value="Trung bình"
                  checked={appearance === "Trung bình"}
                  onChange={(e) => setAppearance(e.value)}
                />
              </div>
            </div>
            <div className="form-group">
              <SelectInput
                label="Thương hiệu"
                value={brand}
                onChange={(e) => {
                  setBrand(e.value);
                  setSelectedPimProduct(null);
                }}
                options={brandOptions}
              />
            </div>
            {selectedProduct && isRTX3060OrHigher(selectedProduct.Dòng) && (
              <div className="form-group">
                <h3>Có box</h3>
                <Checkbox
                  inputId="hasBox"
                  checked={hasBox}
                  onChange={(e) => setHasBox(e.checked)}
                />
                <label htmlFor="hasBox" className="p-checkbox-label" style={{marginLeft: '10px'}}>Có box</label>
              </div>
            )}
          </div>
        </Card>

        {bill.length === 0 && (
          <div
            className="bill"
            style={{ visibility: bill?.length > 0 ? "visible" : "hidden" }}
          >
            <h3>Vui lòng nhập mô tả sản phẩm !</h3>
          </div>
        )}

        <div
          className="bill"
          style={{ visibility: bill?.length > 0 ? "visible" : "hidden" }}
        >
          <h3>Tổng giá trị sản phẩm - {selectedProduct?.Dòng}</h3>
          <table>
            <thead>
              <tr>
                <th>Mục</th>
                <th>Giá (vnđ)</th>
              </tr>
            </thead>
            <tbody>
              {bill.map((item, index) => (
                <tr key={index}>
                  <td>{item.muc}</td>
                  <td>{item.gia}</td>
                </tr>
              ))}
              <tr>
                <td>
                  <strong>Tổng</strong>
                </td>
                <td>{formatPrice(calculateTotal(bill))}</td>
              </tr>
              <tr>
                <td>
                  <strong>SKU</strong>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      justifyContent: "end",
                    }}
                  >
                    {selectedPimProduct
                      ? selectedPimProduct.product_sku
                      : "Chưa xác định"}
                    <Button
                      label={selectedPimProduct ? "Cập nhật" : "Chọn "}
                      severity="success"
                      icon="pi pi-search"
                      onClick={onShowProductPimSelector}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            style={{ margin: "auto", width: "fit-content", marginTop: "20px" }}
          >
            <Button
              label="Xác nhận mua"
              className="p-button-primary"
              onClick={() => {
                selectedPimProduct
                  ? setDialogVisible(true)
                  : toast.current.show({
                      severity: "error",
                      summary: "Missing Information",
                      detail: `SKU chưa xác định !`,
                      life: 5000,
                    });
              }}
            />
          </div>
        </div>
      </div>

      {state.showProductPimSelector && (
        <ProductPimSelect
          productPriceInfo={state.productPriceInfo}
          onCancel={onCloseProductPimSelector}
          onConfirm={onConfirmProductPimSelector}
        />
      )}
      <ConfirmPurchaseDialog
        visible={dialogVisible}
        onHide={() => setDialogVisible(false)}
        productName={selectedProduct ? `${selectedProduct.Dòng} ${brand}` : ""}
        condition={condition}
        totalPrice={bill?.length > 0 ? calculateTotal(bill) : 0}
      />
      <ProductListDialog
        visible={productListDialogVisible}
        onHide={handleCancelProductSelection}
        products={products}
        filteredProducts={filteredProducts}
        selectedProduct={tempSelectedProduct}
        onSearch={handleSearch}
        onRowClick={handleRowClick}
        showPrice={showPrice}
        condition={condition}
        onConfirm={handleConfirmProductSelection}
        onCancel={handleCancelProductSelection}
      />
    </div>
  );
};

export default ProductPricing;
